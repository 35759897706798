import { SPARKY_NAV3_LEFT_NAV_EXTPOINT, SPARKY_NAV3_ROUTES_EXTPOINT } from '@sparky/framework/extpoints'
import type { ExtensionsExports, AuthState, FeatureInfo, SparkyFramework } from '@sparky/framework'
import { i18n } from './i18n'
import { isPbaTenant } from './auth_util'
import { isFeatureEnabled } from './feature'

export const extensions: ExtensionsExports = ({ sparkyFramework }: { sparkyFramework: SparkyFramework }) => {
	const intl = i18n(navigator.language ?? 'en')
	const epmConfigRootPath = '/configuration/prisma-access-agent'

	const isMfeFeatureEnabled = () => (
		sparkyFramework.getFeatureFlag('epm.activation.isPanHybrid') ||
		sparkyFramework.getFeatureFlag('epm.activation.isNgfw')
	)
	const nav3routeExt = {
		point: SPARKY_NAV3_ROUTES_EXTPOINT,
		configs: [
			{
				id: 'epm.nav.routes.paa.inventory',
				config: {
					path: '/configuration/prisma-access-agent/inventory',
					replacePaths: ['/manage/inventory'],
					contentClassName: 'panwds-tw3',
					component: () => import('../main').then((m) => m.activate()),
					isAllowed: () => isPbaTenant(sparkyFramework.getAuthState()) || isFeatureEnabled(sparkyFramework.getMainState()?.featuresInfo, 'ztna_agent') || isMfeFeatureEnabled()
				}
			},
			{
				id: 'epm.nav.routes.paa.epm-config',
				config: {
					path: '/configuration/prisma-access-agent/epm-config',
					replacePaths: ['/settings/epm-config', '/workflows/epm-config'],
					contentClassName: 'panwds-tw3',
					component: () => import('../epm-config').then((m) => m.activate(epmConfigRootPath)),
					isAllowed: () => !isPbaTenant(sparkyFramework.getAuthState()) && isMfeFeatureEnabled()
				}
			}
		]
	}

	const nav3LeftNavExt = {
		point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
		configs: [
			{
				id: 'epm.nav.left',
				config: {
					navigation: () => [
						{
							key: 'configuration',
							path: '/configuration',
							title: intl.formatMessage({ defaultMessage: 'Configuration', id: '7OW8BT'}),
							expandable: true,
							children: [
								{
									key: 'paa',
									path: '/configuration/prisma-access-agent',
									title: intl.formatMessage({ defaultMessage: 'Prisma Access Agent', id: 'xtExMx' }),
									children: [
										{
											key: 'endpoint-management',
											path: '/configuration/prisma-access-agent/inventory',
											title: intl.formatMessage({ defaultMessage: 'Endpoint Management', id: 'zSALLv' }),
											avail: (({ auth, featureInfo }: { auth: AuthState; featureInfo: FeatureInfo[] }) => {
												return isPbaTenant(auth) || isFeatureEnabled(featureInfo, 'ztna_agent') || isMfeFeatureEnabled()
											})
										},
										{
											key: 'settings',
											path: '/configuration/prisma-access-agent/epm-config/setup/infra-setting',
											title: intl.formatMessage({ defaultMessage: 'Settings', id: 'D3idYv' }),
											avail: ({ auth }: { auth: AuthState }) => !isPbaTenant(auth) && isMfeFeatureEnabled()
										},
										{
											key: 'forwarding-profiles',
											path: '/configuration/prisma-access-agent/epm-config/forwarding-profiles/profile',
											title: intl.formatMessage({ defaultMessage: 'Forwarding Profiles', id: 'sll10a' }),
											avail: ({ auth }: { auth: AuthState }) => !isPbaTenant(auth) && isMfeFeatureEnabled()
										}
									]
								}
							]
						}
					]
				}
			}
		]
	}

	return [nav3LeftNavExt, nav3routeExt]
}