import { AuthState } from '@sparky/framework'
import { isFeatureFlagEnabled } from './feature'

export const isPanoramaTenant = (auth: AuthState) => {
	return !!auth.instances.get('prisma_access_edition_onprem')
}

export const isPbaTenant = (auth: AuthState) => {
	return !!auth?.['properties']?.['fawkes.license']?.value?.is_pba_enabled
}

export const isPanHybridEnabled = (auth: AuthState) => {
	return isFeatureFlagEnabled(auth?.['feature_flags'], 'epm.activation.isPanHybrid')
}

export const isNgfwEnabled = (auth: AuthState) => {
	return isFeatureFlagEnabled(auth?.['feature_flags'], 'epm.activation.isNgfw')
}