import type { FeatureInfo } from '@sparky/framework'

export function isFeatureEnabled(featureInfo: FeatureInfo[] | undefined, featureKey: string) {
	return !!featureInfo?.some((f) => f.name === featureKey && f.toggle === 1)
}

export function getEpmConfigRootKey(isPanoramaTenant: boolean) {
	return isPanoramaTenant ? 'settings' : 'workflows'
}

export function isEpmConfigAvail() {
	return true
}
export function isFeatureFlagEnabled(
	featureFlagInfo: { name: string; value: boolean }[] | undefined,
	featureKey: string
) {
	return !!featureFlagInfo?.some((f) => f.name === featureKey && f.value === true)
}
